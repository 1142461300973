$t: 1s;
$d: 0.08em;
$n: 3;
$e: cubic-bezier(0.860, 0.000, 0.070, 1.000); 
$front: linear-gradient(175deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 65%, rgba(252,176,69,1) 100%);
$back: rgb(184, 230, 248);

.popout{
	font-family: Futura, sans-serif;
	font-weight: 900;
	font-size: 3rem;
	letter-spacing: 0.08em;

	@keyframes ani{
		0%{
			transform: translate3d(0,0,0);
			color: $front;
		}
		30%{
			transform: translate3d(0,0,0);
			color: $front;
		}
		70%{
			transform: translate3d($d,-$d,0);
			color: $front;
		}
		100%{
			transform: translate3d($d,-$d,0);
			color: $front;
		}
	}
	span{
		position: relative;
		display: inline-block;
		animation: ani $t infinite alternate $e;
		@for $i from 1 through $n{
			&:nth-last-child(#{$i}n){ animation-delay: calc(-1 * $t * $i / $n / 2);}
		}
	}
	
}